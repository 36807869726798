/* @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@font-face {
  font-family: "Brand";
  src: url("../public/Brand.woff");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: rgb(50, 50, 50);
}

body {
  margin: 0% 25%;
  font-family: "Roboto", sans-serif;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  font-family: "Brand";
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.2rem;
}

h3 {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem 0rem;
  font-weight: bold;
}

h6 {
  font-size: 1rem;
}

p,
li {
  line-height: 1.5rem;
  font-family: "Roboto Slab", serif;
  padding: 1rem 0rem;
  font-size: 1.1rem;
}

ol,
ul {
  padding-left: 1.5rem;
}
a {
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  body {
    margin: 0% 0%;
  }
  nav {
    padding: 3rem 0rem;
  }
} */
/* Header */
.navmenu {
  display: flex;
  padding: 10px 150px;
}

.navitems {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
}

.navitems a:hover {
  transform: scale(1.05);
}

.navitems a:active {
  transform: scale(1);
}

.mainlogo {
  height: 56px;
  cursor: pointer;
}

.navitems a {
  all: unset;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Helvetica, Arial,
    sans-serif;
  color: #0d4137 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  padding: 0rem 25px;
}

/* Main */
.main {
  padding-top: 90px;
  display: flex;
  justify-content: space-around;
}

.bg {
  position: absolute;
  background: #fcfcf7;
  border-radius: 70px;
  height: 750px;
  width: 1200px;
  z-index: -1;
  margin-left: -100px;
}

.content {
  display: flex;
  width: 1250px;
}

.left-section {
  margin-left: 50px;
  flex: 0 0 auto;
  width: 50%;
}

.right-section {
  flex: 0 0 auto;
  width: 50%;
}

h1 {
  font-family: SimpleFontAstetbold, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 65px !important;
  color: #008c74;
  margin-top: 100px;
}

.left-section p {
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #0d4137;
  margin-top: 5vh;
  max-width: 500px;
}

.download {
  font-family: SimpleFontAstetbold, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  padding: 2.1% 8%;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(270deg, #008a72 0%, #00bd9c 100%);
  border-radius: 40px;
  border-style: none;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.download:hover {
  background: linear-gradient(270deg, #00a387 0%, #00a387 0.01%, #00d6b2 100%);
  color: white;
}

.download:active {
  background: linear-gradient(270deg, #008a72 0%, #00bd9c 100%);
}

.readmore {
  font-family: SimpleFontAstetbold, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #0d4137;
  border: 3px solid #0d4137;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 1.7% 8%;
  margin-left: 2vh;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  background: none;
}

.readmore:hover {
  border: 3px solid #00a387;
  color: #00a387;
}

.readmore:active {
  color: #0d4137;
  border: 3px solid #0d4137;
}

.apple {
  height: 40px;
}

.nawl {
  height: 50px;
}

.reviews {
  height: 50px;
}

.apppreview {
  width: 500px;
}

.achievement {
  margin-top: 40px;
  max-width: 440px;
  display: flex;
  justify-content: space-around;
}

/* Features */
.aboutsection {
  text-align: center;
}

.appswelovem {
  display: none;
  height: 60px;
}

h3 {
  margin: 0;
}

h2 {
  font-family: SimpleFontAstetbold, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 50px !important;
  line-height: 66px;
  /* identical to box height */
  text-align: center;
  color: #0d4137;
  margin-top: 80px !important;
  margin-bottom: 70px !important;
}

.aboutsection li {
  width: 550px;
  text-align: center;
  display: inline-block;
  list-style: none;
  justify-items: center;
  margin: 50px 70px;
}

.aboutsection h3 {
  font-family: SimpleFontAstetbold, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 60px;
  text-align: center;
  color: #006f5c;
}

.aboutsection p {
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #0d4137;
  margin: 0;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

/* socials */
.social {
  margin-top: 40px;
  padding-top: 80px;
  margin-bottom: -17vh;
}

.mainsocial {
  padding-top: 80px;
  background-color: #fcfcf7;
}

.socialsectionimg {
  text-align: center;
}

.ratings {
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

.socialsectionimg img {
  text-align: center;
  width: 220px !important;
  height: auto;
  margin: 0vh 5vh;
}

.testimonials {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #fff;
  box-shadow: 0 9px 26px rgb(58 87 135 / 10%);
  padding: 40px 50px 20px;
  border-radius: 40px;
  z-index: 1;
}

.social h3 {
  font-family: SimpleFontAstetBold, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  text-transform: uppercase !important;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.social p {
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.itemright {
  opacity: 0.5;
  z-index: 0;
  transform: scale(0.9) rotate(2deg) !important;
  margin-left: -40px !important;
}

.itemleft {
  opacity: 0.5;
  transform: scale(0.9) rotate(-2deg) !important;
  z-index: 0;
  margin-right: -40px !important;
}

.socialend {
}

/* Footer */
/*Foter*/
.footersv {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0vh !important;
}

.row {
  margin-left: 10%;
  margin-right: 10%;
}

.footermain {
  font-family: SimpleFontAstetbold, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */
  color: #000000;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 10px !important;
}

.socialicons {
  display: flex;
  width: 175px;
  justify-content: space-around;
  margin-left: auto;
  margin-top: 10px;
}

.socialicons a:hover {
  transform: scale(1.05);
}

.socialicons a:active {
  transform: scale(0.95);
}

.footerlink {
  font-family: SimpleFontAstet;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */
  color: #000000;
  text-decoration: none;
}

.footerleft {
  text-align: left;
  padding-top: 100px;
  padding-left: 0 !important;
}

.footerleft a {
  display: flex;
  flex-direction: column;
}

.footerright {
  text-align: right;
  margin-top: 0vh !important;
  padding-right: 0 !important;
}

.footersv p {
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
}

.footmenu2 {
  padding-top: 50px;
}

.endsection {
  padding-left: 10%;
  padding-right: 10%;
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  text-decoration: none;
  color: #000000;
}

.Copyright {
  text-decoration: none;
  color: #000000;
  float: left;
}

.madefrom {
  text-decoration: none;
  color: #000000;
  text-align: right;
  display: inline-block;
  float: right;
  margin-bottom: 1vh;
}

.madefrommobile {
  display: none;
}

.footersv {
  margin-left: 10% !important;
  margin-right: 10% !important;
}

.footermain:hover {
  color: black;
  cursor: default;
}

.footerlink:hover {
  color: #028a71;
  cursor: pointer;
}

.listing-carousel-button-prev {
  scale: 0.7;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  border-radius: 50px;
  padding: 9%;
  border: 2px solid #000000;
}

.listing-carousel-button-next {
  border: 2px solid #000000;
  border-radius: 50px;
  padding: 9%;
}

.listing-carousel-button-prev:hover {
  background: white;
  border: white;
  padding: 11%;
  outline: none;
}

.listing-carousel-button-next:hover {
  background: white;
  border: white;
  padding: 11%;
}

.listing-carousel-button-prev:focus {
  outline: none;
}

.listing-carousel-button-next:focus {
  outline: none;
}

.socialicons1:hover {
  filter: invert(30%);
}

.thanksforcontact {
  margin-top: 5vh;
  color: #00a387 !important;
  text-align: center;
}

.downloadas1:hover {
  transform: scale(1.02);
}

.playstore:hover {
  transform: scale(1.02);
}

.downloadas1:active {
  transform: scale(0.98);
}

.playstore:active {
  transform: scale(0.98);
}

/* Blog */
.blogmainarea {
  margin-left: 10%;
  margin-right: 10%;
  min-height: 900px;
}

.singlepost {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  margin-top: 100px;
  min-height: 900px;
}

/* Ontact Form */
/* Style inputs with type="text", select elements and textareas */
input,
select,
textarea {
  width: 100%;
  /* Full width */
  padding: 12px;
  /* Some padding */
  border: 1px solid #ccc;
  /* Gray border */
  border-radius: 4px;
  /* Rounded borders */
  box-sizing: border-box;
  /* Make sure that padding and width stays in place */
  margin-top: 6px;
  /* Add a top margin */
  margin-bottom: 16px;
  /* Bottom margin */
  resize: vertical;
  /* Allow the user to vertically resize the textarea (not horizontally) */
  background: rgba(255, 255, 255, 0.95);
  border: 3px solid rgba(72, 108, 190, 0.15);
  border-radius: 15px;
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  -webkit-text-size-adjust: unset;
  background-color: #196758;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 3px solid rgba(72, 108, 190, 0.15);
  border-radius: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  min-height: 50px;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  background-color: #196758;
}

/* Add a background color and some padding around the form */
.container {
  border-radius: 5px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  margin-top: 80px;
  margin-bottom: 120px;
}

.container h1 {
  margin-top: 0;
}

.App {
  overflow: hidden;
}

/* MOBILE OPTIMIZATION */
@media screen and (max-width: 15360px) and (min-width: 1920px) {
  .App {
    width: 1920px !important;
    justify-content: center !important;
    margin: auto !important;
  }
}

@media screen and (max-width: 1409px) {
  .aboutsection li {
    margin: 50px 10px;
    width: 480px;
  }
}

@media screen and (max-width: 1350px) {
  .navmenu {
    padding: 10px 50px;
  }

  .bg {
    display: none;
  }

  .left-section {
    margin-left: 0;
    padding-left: 50px;
  }
}

@media screen and (max-width: 1050px) {
  .endsection {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .endsection p {
    margin: 5px 0px;
    text-align: center;
  }

  .footerright {
    text-align: left;
    margin: 40px 0px;
    margin-top: 40px;
  }

  .footerleft {
    margin-bottom: 40px;
  }

  .socialicons {
    margin-left: 0;
  }
}

@media screen and (max-width: 1000px) {
  .main {
    padding-top: 25px;
  }

  .mainsocial {
    display: none;
  }

  .socialend {
    display: none;
  }
}

@media screen and (max-width: 855px) {
  .left-section {
    width: 100%;
    padding-left: 0px;
    text-align: center;
  }

  .right-section {
    display: none;
  }

  .left-section p {
    margin-top: 2vh;
    margin-left: auto;
    margin-right: auto;
  }

  .achievement {
    margin-right: auto;
    margin-left: auto;
  }

  h2 {
    font-size: 45px !important;
    margin-bottom: 0 !important;
  }

  .aboutsection li {
    max-width: 550px;
    width: auto;
    margin: 30px 10px;
  }
}

@media screen and (max-width: 749px) {
  .navmenu {
    padding: 10px 0;
  }

  .navitems {
    display: none;
  }

  .mainlogo {
    margin-left: auto;

    margin-right: auto;
  }
}

@media screen and (max-width: 540px) {
  .blogmainarea {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 450px) {
  .left-section h1 {
    margin-top: 2vh;
    font-size: 50px !important;
  }

  .readmore {
    display: none;
  }

  .apple {
    display: none;
  }

  h2 {
    font-size: 35px !important;
    margin-bottom: 0 !important;
  }

  .aboutsection h3 {
    font-size: 35px;
    line-height: 45px !important;
    margin: 15px 2px;
  }
}

@media screen and (max-width: 399px) {
  .mainlogo {
    height: 40px;
  }
}

/* Linktree */
.links {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

.links a {
  text-decoration: none;
}

.links {
  /* variáveis */
  --bg-color: #edf2ff;
  --text-color: #00082f;
  --primary-color: #4263eb;
  --primary-color-dark: #364fc7;
}

.links body.dark-blue {
  --bg-color: #00082f;
  --text-color: #ffffff;
}

.links body.pink {
  /* --bg-color: #1877641c; */
  --text-color: #1f000b;
  --primary-color: #10110f;
  --primary-color-dark: #a61e4d;
}

.links body.dark-pink {
  /* --bg-color: #1F000B; */
  --text-color: #ffffff;
  --primary-color: #d6336c;
  --primary-color-dark: #a61e4d;
}

.links body {
  /* background: var(--bg-color); */
  color: var(--text-color);
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.links .container {
  text-align: center;
  width: 300px;
  margin: 60px auto;
}

.links img {
  width: 100%;
  background: white;
}

.links img.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 3.7px;
  border: var(--primary-color) solid 4px;
}

.links h1 {
  font-size: 32px !important;
  margin-top: 24px !important;
  margin-bottom: 8px !important;
}

.links .username {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  opacity: 0.8;
  font-size: 18px;
}

.links ul {
  list-style: none;
  margin: 48px 0;
  padding: 20px;
}

.links ul li a {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: 0;
  color: white;
  text-transform: uppercase;
  font-size: 14px;

  margin-bottom: 16px;
  border-radius: 6px;
}

.linksfooter {
  font-weight: 500;
  font-size: 12px;
  opacity: 0.6;
  transition: opacity 400ms;
}

.links footer:hover {
  opacity: 1;
}

.links footer a {
  color: var(--text-color);
}

form label {
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-size: 1.25em;
  color: #171717;
}

form input {
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  color: #171717;
}

form textarea {
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-size: 1.1em;
  color: #171717;
  height: 10e;
}

.clock {
  font-size: 100%;
  width: 3em;
  height: 3em;
  background: transparent;
  border: 5px solid rgb(255, 255, 255);
  border-radius: 50%;
  position: relative;
}

.clock:after,
.clock:before {
  position: absolute;
  content: "";
  background: rgb(255, 255, 255);
  border-radius: 50%;
}

.clock:after {
  width: 1.3em;
  height: 0.15em;
  top: 1.45em;
  left: 1.45em;
  transform-origin: 0.1em 0.1em;
  animation: minutehand 0.75s linear infinite;
}

.clock:before {
  width: 1em;
  height: 0.2em;
  top: 1.45em;
  left: 1.45em;
  transform-origin: 0.1em 0.1em;
  animation: hourhand 6s linear infinite;
}

@keyframes minutehand {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes hourhand {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Watches */
.clock {
  font-size: 100%;
  width: 3em;
  height: 3em;
  background: transparent;
  border: 5px solid rgb(255, 255, 255);
  border-radius: 50%;
  position: relative;
  transform: scale(0.47);
  margin-top: -72px;
  margin-left: auto;
  margin-right: 15px;
}

.clock:after,
.clock:before {
  position: absolute;
  content: "";
  background: rgb(255, 255, 255);
  border-radius: 50%;
}

.clock:after {
  width: 1.3em;
  height: 0.15em;
  top: 1.45em;
  left: 1.45em;
  transform-origin: 0.1em 0.1em;
  animation: minutehand 0.75s linear infinite;
}

.clock:before {
  width: 1em;
  height: 0.2em;
  top: 1.45em;
  left: 1.45em;
  transform-origin: 0.1em 0.1em;
  animation: hourhand 6s linear infinite;
}
@keyframes minutehand {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes hourhand {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Confirm */
.swal-icon--success {
  border-color: #ffffff;
}

.swal-icon--success:after,
.swal-icon--success:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: rgba(255, 255, 255, 0);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.swal-icon--success:before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}

.swal-icon--success:after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

.swal-icon--success__ring {
  width: 80px;
  height: 80px;
  border: 7px solid #ffffff;
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -7px;
  top: -7px;
  z-index: 2;
}

.swal-icon--success__hide-corners {
  width: 5px;
  height: 90px;
  background-color: rgba(255, 255, 255, 0);
  padding: 1px;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.swal-icon--success__line {
  height: 5px;
  background-color: #ffffff;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.swal-icon--success__line--tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}

.swal-icon--success__line--long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}

.swal-icon {
  width: 80px;
  height: 80px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 0;
  transform: scale(0.3);
  box-sizing: content-box;
  margin-top: -87px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: 0px;
}

.swal-icon:first-child {
  margin-top: 32px;
}
.loading {
  display: relative;
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }

  to {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }

  to {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  to {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
.recaptcha {
  display: flex;
  justify-content: center;
}
.privacypolicysection h1 {
  font-size: 25px !important;
  color: #000000;
}
.privacypolicysection .container {
  margin-top: 10px;
}
.privacypolicysection p {
  margin-top: 0;
  margin-bottom: 0;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.supportsection p {
  all: unset;
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 0;
}
.supportsection a {
  all: unset;
  font-family: SimpleFontAstet, Roboto, -apple-system, BlinkMacSystemFont,
    Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
}
